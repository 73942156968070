<template>
<div> 
  <v-navigation-drawer 
    disable-resize-watcher
    stateless
    v-if="$vuetify.breakpoint.smAndUp"
    v-model="drawer" 
    class="drawer_admin"    
    :persistent="true"
    hide-overlay
    width='15%' 
    :color="color" 
    :mini-variant="miniVariant" 
    :mini-variant-width="'8%'" 
    :src="bg" 
    dark>
    <v-layout 
      column 
      class="my-fill-height">
      <v-container>
        <v-layout 
          row 
          wrap
            justify-center>
          <img 
          class="company_logo" 
          src="/static/drawer_logo.png">
        </v-layout>
      </v-container>
      <v-divider></v-divider>
        <v-btn
          color="white"
          :style="miniVariant ? 'margin-right: -30px; margin-top: 40px; max-width: 10px; max-width: 25px; max-height: 25px':'margin-right: -30px; margin-top: 70px; max-width: 25px; max-height: 25px'"
          @click="miniVariant = !miniVariant"
          dark
          right
          small
          absolute
          fab
        >
          <v-icon color="primary">{{miniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
        </v-btn>
      <v-layout column>
        <v-list
         dense>
          <v-list-item
           elevation ="0"
           active-class="blue lighten-1"
           v-for="(item) in items"
           :key="item.to"
           :to="item.to"
         >
            <v-list-item-icon>
              <v-tooltip
               bottom>
                <template
                 v-slot:activator="{ on }"
               >
                  <span
                   v-on="on">
                    <div>
                      <v-icon
                       :color="'white'"
                       v-html="item.icon"
                     />
                    </div>
                  </span>
                </template>
                <span
                v-if="miniVariant"
                 v-html="item.title"
               />
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
             :style="'color: white'"
             class="primaryText--text menu-text"
             v-text="item.title"
           />
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <v-list 
          class="logout-tile set_cursor">
          <v-list-item>
            <v-list-item-icon>
              <v-tooltip 
              bottom>
                <template 
                  v-slot:activator="{ on }">
                  <span 
                  v-on="on">
                    <div>
                      <v-icon 
                        @click="logout" 
                        :color="'white'" 
                        v-html="'mdi-logout'" />
                    </div>
                  </span>
                </template>
                <span 
                  v-html="'Sign Out'" />
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title 
                @click="logout"
                class="logout-tile" 
                :style="'white'" 
                v-text="'Sign Out'" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-layout>
  </v-navigation-drawer>
  <v-card
    v-else
    style="position: fixed; top: 0; z-index: 100; width: 100%;"
    color="grey lighten-4"
    flat
    tile
  >
    <v-toolbar 
      height="70px"
      style="overflow: hidden;"
      color="primary"
      dense>
      <v-layout
        row
        justify-center>
        <img 
          style="max-width: 50px; margin-left: 15%"
          class="company_logo" 
          src="/static/drawer_logo.png">
      </v-layout>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon
              :color="'white'"
            >
            mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-card
          color="primary"
          dark
        >
          <v-list color="transparent">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :to="item.to"
            >
              <v-icon               
               style="font-size: 14px; margin-right: 10px"
                >{{item.icon}}
              </v-icon>
              <v-list-item-title 
                v-text="item.title" 
                :style="'color:white; font-size: 14px'"
              />
            </v-list-item>
            <v-list-item
              @click="logout">
              <v-list-item-title
                v-text="'Sign Out'" 
                :style="'color:white; font-size: 14px'"
              />
              <v-icon               
               style="font-size: 14px; margin-right: 10px">
               mdi-logout
              </v-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>
  </v-card>
</div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Outages", 
          icon: "mdi-lightbulb-on", 
          to: "/operator/outages" 
        },
        {
          title: "Notifications", 
          icon: "mdi-bell-ring", 
          to: "/operator/notifications" 
        },
      ],
      drawer: true,
      color: 'primary',
      right: false,
      miniVariant: false,
      expandOnHover: false,
      background: false,
    }
  },
  methods: {
    closeConditional (e) {
      return (
        (window.innerWidth >= this.mobileBreakPoint && !this.drawer)
        || !this.closeOnClick
        || this.$el.contains(e.target)
      )
    },
    logout () {
      this.$store.dispatch('logout')
        .then(resp => {
          this.$store.commit('toggle_alert', resp)
          this.$router.go()
        })
        .catch(err => {
          this.$store.commit('toggle_alert', err)
        })
    }
  },
  computed: {
    bg() {
      return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
    },
  },
}
</script>

<style scoped>
.drawer_admin {
  overflow: visible;
  position: fixed;
}

.v-navigation-drawer {
  -webkit-box-shadow: 28px -1px 34px -34px rgba(0,0,0,0.75);
  -moz-box-shadow: 28px -1px 34px -34px rgba(0,0,0,0.75);
  box-shadow: 28px -1px 34px -34px rgba(0,0,0,0.75);
}
.logout-tile {
  color: white;
  font-size: 12px
}
.v-divider {
  background-color: rgb(3, 141, 210)
}
.set_cursor {
  cursor: pointer;
}
.my-fill-height {
  height: 100vh;
}
.company_name {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: 'Aleo', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 20px;
  max-width: 150px
}
.company_name_mobile {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: 'Aleo', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 15px;
  max-width: 150px
}
.company_logo {
  height: 90%;
  width: 90%
}
</style>
